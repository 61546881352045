import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';


import Header from "src/components/headers/headerDemenagement"
import Ariane from "src/components/ariane"
import Footer from "src/components/footers/footer"
import Sidebar from "src/components/sidebars/sidebarDemenagement"

//Test

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: { 
        date, 
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane
      },
      body
    }
  }
}) => {
  return (
      <div className="bg-white">
        <Helmet>
          <title>{title_meta}</title>
          <meta name="description" content={description_meta} />
        </Helmet>
        <header className="sticky top-0 z-50 shadow-lg bg-white">
          <Header />
          <Ariane fil_ariane={fil_ariane} />
        </header>

        <div class="relative max-w-7xl mx-auto h-full lg:flex">
          
            <main className="">
              <div class="flex flex-1 overflow-hidden pr-4">
                <div class="flex flex-1 flex-col">
                  <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                    <div className="relative py-16 bg-white overflow-hidden">
                      <div className="relative px-4 sm:px-6 lg:px-8">
                        <div className="text-lg max-w-prose mx-auto">
                          <h1>
                            <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                              {title}
                            </span>
                          </h1>
                          <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                            {excerpt}
                          </p>

                          <GatsbyImage className="rounded-lg shadow-lg" alt={thumbnailText} image={getImage(embeddedImagesLocal[0])} />
                          
                        </div>

                        <div 
                          className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto"
                        >
                          <MDXProvider>
                            <MDXRenderer
                              className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto"
                              remoteImages={embeddedImagesRemote}
                              localImages={embeddedImagesLocal}
                            >
                              {body}
                            </MDXRenderer>
                          </MDXProvider>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>


            <aside class="self-start sticky top-16 col-span-1 p-4 lg:py-16 lg:w-1/3">
              <Sidebar />
            </aside>

          </div>
        <footer>
          <Footer />
        </footer>
      </div>    
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;



